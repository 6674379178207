import { Link } from "react-router-dom";
import { Box } from "@chakra-ui/react";

const Slide = ({ name, image, slug, isCarousel, categorySlug }) => {
  return (
    <Box
      as={Link}
      to={`/${categorySlug}/${slug}`}
      // w="sizes.4xl"
      h={!isCarousel ? "sizes.3xl" : "sizes.5xl"}
      bg="grey.800"
      display="block"
      backgroundImage={image.data.attributes.url}
      backgroundPosition="center"
      backgroundSize="cover"
      position="relative"
    >
      <Box
        as="span"
        color="grey.600"
        fontSize="2xl"
        backgroundColor="grey.950"
        position="absolute"
        bottom="0"
        insetX="0"
        textAlign="right"
        paddingX="4"
        paddingY="1"
      >
        {name}
      </Box>
    </Box>
  );
};

export default Slide;

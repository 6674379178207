import { useEffect, useReducer } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import Loader from "../components/Loader.component";
import Error from "../components/Error.component";
import StartScreen from "../components/StartScreen.component";
import Question from "../components/Question.component";
import NextButton from "../components/NextButton.component";
import Progress from "../components/Progress.component";
import FinishScreen from "../components/FinishScreen.component";
import InnerContainer from "../components/InnerContainer.component";

import { QUIZDETAILS } from "../queries/queries";

const initState = {
  questions: [],
  // 'loading', 'error', 'ready', 'active', 'finished'
  status: "loading",
  index: 0,
  answer: null,
  points: 0,
  description: "The default description text",
  image: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "dataLoading":
      return { ...state, status: "loading" };

    case "dataReceived":
      return {
        ...state,
        questions: action.payload?.attributes.questions.data,
        description: action.payload?.attributes.description,
        image: action.payload?.attributes.heroImage.data.attributes.url,
        status: "ready",
      };

    case "dataFailed":
      return { ...state, status: "error" };

    case "start":
      return { ...state, status: "active" };

    case "newAnswer":
      const question = state.questions.at(state.index);
      return {
        ...state,
        answer: action.payload,
        points: question.attributes.answers[action.payload].isCorrect
          ? state.points + 1
          : state.points,
      };

    case "nextQuestion":
      return { ...state, index: state.index + 1, answer: null };

    case "finish":
      return { ...state, status: "finished" };

    default:
      throw new Error("Action is unknown");
  }
}

const Game = () => {
  const { slug } = useParams();
  const [
    { status, questions, index, answer, points, description, image },
    dispatch,
  ] = useReducer(reducer, initState);
  const { loading, error, data } = useQuery(QUIZDETAILS, {
    variables: { slug: slug },
  });

  useEffect(() => {
    if (loading) {
      dispatch({ type: "dataLoading" });
    }

    if (error) {
      dispatch({ type: "dataFailed", payload: error });
    }

    dispatch({
      type: "dataReceived",
      payload: data?.quizAppQuizs.data[0],
    });
  }, [loading, error, data]);

  const numQuestions = questions?.length;

  return (
    <Box as="main" marginBottom={[4, 4, 6, 8]}>
      {status === "loading" && <Loader />}
      {status === "error" && <Error />}
      {status === "ready" && (
        <StartScreen
          description={description}
          image={image}
          dispatch={dispatch}
        />
      )}
      <InnerContainer>
        {status === "active" && (
          <>
            <Progress
              i={index}
              numQuestions={numQuestions}
              points={points}
              answer={answer}
            />
            <Question
              question={questions[index]}
              dispatch={dispatch}
              answer={answer}
            />
            <NextButton
              dispatch={dispatch}
              answer={answer}
              index={index}
              numQuestions={numQuestions}
            />
          </>
        )}
        {status === "finished" && (
          <FinishScreen points={points} numQuestions={numQuestions} />
        )}
      </InnerContainer>
    </Box>
  );
};

export default Game;

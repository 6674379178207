// Swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Autoplay, Pagination } from "swiper/modules";

import Slide from "./Slide.component";
import { Box } from "@chakra-ui/react";

const Carousel = ({ sliderContainerWidth, items }) => {
  const slidesPerLine = sliderContainerWidth < 860 ? 1 : 2;

  return (
    <Box maxW="container.xxl" marginX="auto">
      <Swiper
        slidesPerView={slidesPerLine}
        spaceBetween={4}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3500 }}
        loop={true}
        slidesPerGroup={slidesPerLine}
        modules={[Autoplay, Pagination]}
        className="mySwiperCarousel"
      >
        {items.map(
          ({ id, attributes: { name, heroImage, slug, categories } }) => (
            <SwiperSlide key={id}>
              <Slide
                name={name}
                image={heroImage}
                slug={slug}
                isCarousel={true}
                categorySlug={categories.data[0].attributes.slug}
              />
            </SwiperSlide>
          )
        )}
      </Swiper>
    </Box>
  );
};

export default Carousel;

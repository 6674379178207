import { theme } from "@chakra-ui/react";

export const fonts = {
  heading: `'Open Sans', sans-serif`,
  body: `'Raleway', sans-serif`,
};

export const colors = {
  brand: {
    900: "#f0f01e",
    800: "#F62AA0",
    700: "#B8EE30",
    600: "#1098ad",
  },
  grey: {
    950: "rgba(52, 58, 64, 0.6)",
    900: "#343a40",
    800: "#495057",
    700: "#ced4da",
    600: "#f1f3f5",
  },
};

export const sizes = {
  sizes: {
    ...theme.space,
    full: "100%",
    "3xs": "14rem",
    "2xs": "16rem",
    xs: "0.5rem",
    sm: "1rem",
    md: "2rem",
    lg: "4rem",
    xl: "6rem",
    "2xl": "8rem",
    "3xl": "12rem",
    "4xl": "16rem",
    "5xl": "20rem",
  },
  container: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1920px",
  },
};

export const breakpoints = {
  sm: "30em", // 480px
  md: "48em", // 768px
  lg: "64em", // 1024px
  xl: "90em", // 1440px
  "2xl": "120em", // 1920px
};

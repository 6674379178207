import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import "./index.css";
import { colors, sizes, fonts, breakpoints } from "./theme/styles";

import App from "./App";
import Main from "./routes/Main.page";
import CategoryPage from "./routes/CategoryPage.page";
import Game from "./routes/Game.page";
import ErrorPage from "./routes/Error.page";

const client = new ApolloClient({
  // uri: "http://localhost:1337/graphql",
  uri: "https://cms.kora.work/graphql",
  cache: new InMemoryCache(),
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: "/:categoryslug",
        element: <CategoryPage />,
      },
      {
        path: "/:categoryslug/:slug",
        element: <Game />,
      },
    ],
  },
]);

const theme = extendTheme({ colors, sizes, fonts, breakpoints });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ApolloProvider client={client}>
        <RouterProvider router={router} />
      </ApolloProvider>
    </ChakraProvider>
  </React.StrictMode>
);

import { gql } from "@apollo/client";

export const HOMEQUIZLIST = gql`
  query QuizHome {
    quizCategories(filters: { displayOnHomepage: { eq: true } }) {
      data {
        id
        attributes {
          category
          slug
          quizes(filters: { displayOnHomepage: { eq: true } }) {
            data {
              id
              attributes {
                name
                slug
                thumbImage {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                categories {
                  data {
                    id
                    attributes {
                      category
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    quizAppQuizs(
      filters: { useInCarousel: { eq: true } }
      sort: "carouselOrder"
    ) {
      data {
        id
        attributes {
          name
          slug
          heroImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          categories {
            data {
              attributes {
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export const QUIZDETAILS = gql`
  query QUIZDETALS($slug: String) {
    quizAppQuizs(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          name
          questions(pagination: { limit: 100 }) {
            data {
              attributes {
                questionText
                answers {
                  option
                  isCorrect
                }
              }
            }
          }
          description
          heroImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

export const CATEGORYPAGE = gql`
  query CategoryPage($slug: String!) {
    quizCategories(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          category
          slug
          quizes(pagination: { limit: 100 }) {
            data {
              id
              attributes {
                name
                slug
                thumbImage {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                categories {
                  data {
                    id
                    attributes {
                      category
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

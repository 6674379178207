import { Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const FinishScreen = ({ points, numQuestions }) => {
  const navigate = useNavigate();
  return (
    <Flex
      minH="90vh"
      direction="column"
      justify="center"
      align="center"
      gap="10"
    >
      <Text
        as="p"
        className="result"
        fontSize={["ld", "ld", "xl"]}
        textAlign="center"
      >
        You answered correctly {points} out of {numQuestions}.
      </Text>
      <Button
        onClick={() => navigate("/")}
        colorScheme="teal"
        marginInline="auto"
        display="block"
        textTransform="uppercase"
      >
        Return to Main
      </Button>
    </Flex>
  );
};

export default FinishScreen;

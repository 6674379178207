import { VStack, Button } from "@chakra-ui/react";

const Options = ({ answers, dispatch, answer }) => {
  // const hasAnswered = answer !== null;

  return (
    <VStack gap="2">
      {answers.map(({ option }, index) => (
        <Button
          py="8"
          px="6"
          w="sizes.full"
          minH="20"
          h="auto"
          key={index}
          // isDisabled={hasAnswered}
          colorScheme={index === answer ? "teal" : "yellow"}
          whiteSpace="wrap"
          onClick={() => dispatch({ type: "newAnswer", payload: index })}
        >
          {option}
        </Button>
      ))}
    </VStack>
  );
};

export default Options;

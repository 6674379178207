import { Outlet } from "react-router-dom";

import Header from "./components/Header.component";
import Footer from "./components/Footer.component";

export default function App() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

import { Box, Button, Flex, Image } from "@chakra-ui/react";
import InnerContainer from "./InnerContainer.component";

const StartScreen = ({ description, image, dispatch }) => {
  return (
    <Box maxW="container.xxl" marginInline="auto">
      <Image src={image} />
      <InnerContainer>
        <Flex
          flexDirection="column"
          justify="center"
          align="center"
          gap="6"
          paddingTop="6"
        >
          <Box as="p" fontSize={["xl", "xl", "2xl"]}>
            {description}
          </Box>
          <Button
            colorScheme="teal"
            size="md"
            onClick={() => dispatch({ type: "start" })}
          >
            Let's start
          </Button>
        </Flex>
      </InnerContainer>
    </Box>
  );
};

export default StartScreen;

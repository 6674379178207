import { Button } from "@chakra-ui/react";

const NextButton = ({ dispatch, answer, numQuestions, index }) => {
  const hasAnswered = answer !== null;

  if (index < numQuestions - 1)
    return (
      <Button
        justifySelf="flex-end"
        colorScheme="teal"
        marginInline="auto"
        display="block"
        size="lg"
        textTransform="uppercase"
        isDisabled={!hasAnswered}
        onClick={() => dispatch({ type: "nextQuestion" })}
        key={"next"}
      >
        Next
      </Button>
    );

  if (index === numQuestions - 1)
    return (
      <Button
        justifySelf="flex-end"
        colorScheme="teal"
        marginInline="auto"
        display="block"
        textTransform="uppercase"
        size="lg"
        isDisabled={!hasAnswered}
        onClick={() => dispatch({ type: "finish" })}
        key={"finish"}
      >
        Finish
      </Button>
    );
};

export default NextButton;

import { Box, Flex, Image, Text, Link } from "@chakra-ui/react";
import InnerContainer from "./InnerContainer.component";
import { Link as rLink } from "react-router-dom";

import Logo from "../assets/egghead-logo.png";

const Footer = () => {
  const linkHover = {
    borderBottom: "1px solid var(--color-primary)",
  };

  return (
    <Box as="footer" bg="brand.900">
      <InnerContainer>
        <Box
          paddingTop="4"
          display="block"
          w="100%"
          maxW="360px"
          mx="auto"
          as={rLink}
          to="/"
          h="95%"
          borderBottom="1px solid transparent"
          paddingBottom={2}
          transition="0.3s"
          _hover={linkHover}
        >
          <Image src={Logo} alt="Egghead Logo" w="100%" display="block" />
        </Box>
        <Box w="100%" maxW="360px" mx="auto" marginTop="16" paddingBottom="8">
          <Text textAlign="center" textTransform="uppercase" mb="8">
            Top categories
          </Text>
          <Flex direction="column" align="flex-start" gap="2">
            <Link
              as={rLink}
              to="/movies-tv-shows"
              borderBottom="1px solid transparent"
              _hover={linkHover}
            >
              Movies / TV shows
            </Link>
            <Link
              as={rLink}
              to="/sports"
              borderBottom="1px solid transparent"
              _hover={linkHover}
            >
              Sports
            </Link>
            <Link
              as={rLink}
              to="/geography"
              borderBottom="1px solid transparent"
              _hover={linkHover}
            >
              Geography
            </Link>
          </Flex>
        </Box>
      </InnerContainer>
    </Box>
  );
};

export default Footer;

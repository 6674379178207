import { Box, Flex } from "@chakra-ui/react";

const Progress = ({ i, numQuestions, points, answer }) => {
  return (
    <Flex
      width="sizes.full"
      flexDirection="column"
      alignItems="stretch"
      marginBottom="8"
      gap="2"
      fontSize={["18", "18", "20"]}
    >
      <Box as="p" textAlign="center" alignSelf="flex-end">
        Question <strong>{i + 1}</strong>/{numQuestions}
      </Box>
      <Box
        borderRadius="6"
        overflow="hidden"
        w="100%"
        h="2"
        as="progress"
        max={numQuestions}
        value={i + Number(answer !== null)}
      ></Box>
    </Flex>
  );
};

export default Progress;

import { Box, VStack } from "@chakra-ui/react";

import Options from "./Options.component";

const Question = ({ question, dispatch, answer }) => {
  const { questionText, answers } = question.attributes;
  return (
    <VStack
      paddingBottom={[6, 6, 8, 8]}
      gap="6"
      maxWidth={["full", "full", "85%", "60%"]}
      mx="auto"
      align="stretch"
    >
      <Box as="h4" textAlign="center" lineHeight="1">
        {questionText}
      </Box>
      <Options answers={answers} dispatch={dispatch} answer={answer}></Options>
    </VStack>
  );
};

export default Question;

import { Box } from "@chakra-ui/react";

const InnerContainer = ({ children }) => {
  return (
    <Box maxW="container.xxl" marginX="auto" py="4" px={[2, 2, 4, 8, 16]}>
      {children}
    </Box>
  );
};

export default InnerContainer;

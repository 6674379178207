import { Box, Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import SliderLine from "./SliderLine.component";

const CategoryLine = ({
  sliderContainerWidth,
  quizes,
  categoryName,
  categorySlug,
}) => {
  return (
    <Box>
      <Flex
        justifyContent="space-between"
        bgColor="brand.600"
        fontSize={["lg", "lg", "xl"]}
        px={[4, 4, 6]}
        py={2}
        marginBottom={2}
        color="grey.600"
      >
        <Text textTransform="uppercase" fontWeight="700">
          {categoryName}
        </Text>
        <Text as={Link} to={`/${categorySlug}`}>
          See all
        </Text>
      </Flex>
      <SliderLine
        sliderContainerWidth={sliderContainerWidth}
        quizes={quizes}
        categorySlug={categorySlug}
      />
    </Box>
  );
};

export default CategoryLine;

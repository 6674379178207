import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import Loader from "../components/Loader.component";
import ErrorPage from "./Error.page";
import Slide from "../components/Slide.component";

import { CATEGORYPAGE } from "../queries/queries";
import InnerContainer from "../components/InnerContainer.component";
import { Grid, Text } from "@chakra-ui/react";

const CategoryPage = () => {
  const { categoryslug } = useParams();
  const { loading, error, data } = useQuery(CATEGORYPAGE, {
    variables: { slug: categoryslug },
  });

  if (loading) return <Loader />;
  if (error) return <ErrorPage />;

  const { category, quizes } = data.quizCategories.data[0].attributes;

  return (
    <InnerContainer>
      <Text marginBottom={2} marginTop={6}>
        {category}
      </Text>
      <Grid
        templateColumns="repeat(auto-fit, minmax(260px, 1fr))"
        gap={4}
        marginBottom={8}
      >
        {quizes.data.map(({ id, attributes: { name, thumbImage, slug } }) => (
          <Slide
            key={id}
            name={name}
            image={thumbImage}
            slug={slug}
            categorySlug={categoryslug}
          />
        ))}
      </Grid>
    </InnerContainer>
  );
};

export default CategoryPage;

import { Box, Flex, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import InnerContainer from "./InnerContainer.component";

import Logo from "../assets/egghead-logo.png";

const Header = () => {
  const linkHover = {
    borderBottom: "1px solid var(--color-primary)",
  };

  return (
    <Box as="header" bg="brand.900">
      <InnerContainer>
        <Flex height="sizes.lg" align="center" justify="center">
          <Box
            as={Link}
            to="/"
            h="95%"
            borderBottom="1px solid transparent"
            paddingBottom={2}
            transition="0.3s"
            _hover={linkHover}
          >
            <Image src={Logo} alt="Egghead Logo" h="100%" display="block" />
          </Box>
        </Flex>
      </InnerContainer>
    </Box>
  );
};

export default Header;

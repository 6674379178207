import { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { HOMEQUIZLIST } from "../queries/queries";

import { Box, Flex } from "@chakra-ui/react";
import InnerContainer from "../components/InnerContainer.component";
import CategoryLine from "../components/CategoryLine.component";
import Carousel from "../components/Carousel.component";

const Main = () => {
  const sliderWidthRef = useRef();
  const [windowSize, setWindowSize] = useState(0);
  const { loading, error, data } = useQuery(HOMEQUIZLIST);

  useEffect(() => {
    if (!windowSize) {
      const sliderContainerWidth = sliderWidthRef.current?.clientWidth;
      setWindowSize(sliderContainerWidth);
    }
    const handleWindowResize = () => {
      const sliderContainerWidth = sliderWidthRef.current?.clientWidth;
      setWindowSize(sliderContainerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line
  }, [data]);

  if (loading) return <p>Loading</p>;
  if (error) return <p>GraphQl error</p>;

  const categoriesData = data.quizCategories.data;
  const carouselData = data.quizAppQuizs.data;

  return (
    <Box as="main" bgColor="grey.600">
      <Carousel sliderContainerWidth={windowSize} items={carouselData} />
      <InnerContainer>
        <Flex
          flexDirection="column"
          gap="8"
          marginY={[2, 2, 6]}
          ref={sliderWidthRef}
        >
          {categoriesData && windowSize
            ? categoriesData.map(
                ({ id, attributes: { category, quizes, slug } }) => (
                  <CategoryLine
                    key={id}
                    sliderContainerWidth={windowSize}
                    categoryName={category}
                    quizes={quizes}
                    categorySlug={slug}
                  />
                )
              )
            : null}
        </Flex>
      </InnerContainer>
    </Box>
  );
};

export default Main;

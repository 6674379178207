import { Text } from "@chakra-ui/react";

const Error = () => {
  return (
    <Text as="p" className="error">
      <Text as="span">💥</Text> There was an error fecthing questions.
    </Text>
  );
};

export default Error;

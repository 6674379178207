// Swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import Slide from "./Slide.component";

const SliderLine = ({ sliderContainerWidth, quizes, categorySlug }) => {
  const slideWidth = 300;
  const slidesPerLine =
    sliderContainerWidth < 440
      ? 1
      : sliderContainerWidth < 620
      ? 2
      : Math.floor(sliderContainerWidth / slideWidth);

  return (
    <Swiper
      slidesPerView={slidesPerLine}
      spaceBetween={8}
      navigation={true}
      loop={true}
      slidesPerGroup={slidesPerLine}
      modules={[Navigation]}
      className="mySwiper"
    >
      {quizes.data.map(({ id, attributes: { name, thumbImage, slug } }) => (
        <SwiperSlide key={id}>
          <Slide
            name={name}
            image={thumbImage}
            slug={slug}
            categorySlug={categorySlug}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderLine;
